import React from 'react';
import type { ComponentChildren } from 'preact';
import 'normalize.css';
import 'styles/global.scss';
import 'styles/theme.scss';
import { HeadTags } from 'utils/constants';

interface PageHeadProps {
  children: ComponentChildren;
}

interface PageProps {
  children: ComponentChildren;
}

export function PageHead({ children }: PageHeadProps) {
  return (
    <>
      <title id={HeadTags.siteTitle}>David Who :: Dream Reality</title>
      {children}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel="icon" href="/favicon.ico" sizes="any" />
      {/* 32×32 */}
      <link rel="icon" href="/icon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      {/* 180×180 */}
      <link rel="manifest" href="/manifest.webmanifest" />
      <link
        href="https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
    </>
  );
}

function Page({ children }: PageProps) {
  return <main>{children}</main>;
}
export default Page;
