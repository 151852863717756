import React from 'react';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'utils/constants';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Page Not Found</title>
    </PageHead>
  );
}

function NotFoundPage() {
  return (
    <Page>
      <h1>Not Found</h1>
    </Page>
  );
}

export default NotFoundPage;
